import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

import { useStore, useLoader } from 'common/hooks';
import { Button, Input, Label } from 'common/materials';
import { UserCompany } from 'models/user/UserCompany';

interface Option {
    readonly label: string;
    readonly value: string;
}

type Props = {
    isOpen: boolean;
    user?: UserCompany;
    onClose: () => void;
    onSubmitEdit: (user: UserCompany) => void;
    onEditSuccess?: (selectedCompany: Option[], selectedEmails: Option[]) => void;
};

function EditUserModal(props: Props) {
    const { isOpen, user, onClose, onSubmitEdit, onEditSuccess } = props;
    const screenLoader = useLoader();
    const [selectedUser, setSelectedUser] = useState<UserCompany>();

    useEffect(() => {
        setSelectedUser(user);
    }, [user]);

    const handleChangeSelectedUser = <
        T extends Pick<UserCompany, 'first_name' | 'last_name' | 'email'>,
        K extends keyof T
    >(
        key: K,
        value: T[K]
    ) => {
        setSelectedUser(prevState => {
            if (!prevState) {
                return undefined;
            }

            return {
                ...prevState,
                [key]: value
            };
        });
    };

    const handleSubmitEdit = async () => {
        // When modal is not opened and some user try to submit edit (prevent the hacker!!!)
        if (!selectedUser) {
            return;
        }

        try {
            screenLoader.show();

            await onSubmitEdit(selectedUser);

            fireSwal('Success!', 'แก้ไข User สำเร็จ', 'success').then(() => {
                onClose();
                onEditSuccess && onEditSuccess([], [{ label: '', value: selectedUser.citizen_id }]);
            });
        } catch (error) {
            console.log(error);
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        },
        []
    );

    return (
        <Modal
            size="2xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit User</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="flex flex-col gap-2">
                    <div>
                        <Label>User</Label>
                        <Input
                            disabled
                            type="text"
                            placeholder="User"
                            value={selectedUser?.citizen_id ?? ''}
                        />
                    </div>
                    <div>
                        <Label>First Name</Label>
                        <Input
                            type="text"
                            placeholder="First Name"
                            value={selectedUser?.first_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('first_name', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>Last Name</Label>
                        <Input
                            type="text"
                            placeholder="Last Name"
                            value={selectedUser?.last_name ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('last_name', event.target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label>Email</Label>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={selectedUser?.email ?? ''}
                            onChange={event =>
                                handleChangeSelectedUser('email', event.target.value)
                            }
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="gap-4">
                    <Button
                        outline
                        size="sm"
                        className="text-gray-400"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="sm"
                        onClick={handleSubmitEdit}
                        className="bg-primary-900 text-white"
                    >
                        Edit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditUserModal;
