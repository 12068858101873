import { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { MultiValue, SingleValue } from 'react-select';

import { useDisclosure, useStore } from 'common/hooks';
import { UserCompany } from 'models/user/UserCompany';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useLoader } from 'common/hooks';
import { AddUserRequest } from 'data/user/schemas';
import { addUsers } from 'data/user/repositories/userCompanyRepository';

import { TabLabel } from './UserVerification.view';
import { useLocalStorage } from 'usehooks-ts';

interface Option {
    readonly label: string;
    readonly value: string | UserValueOption;
}

interface UserValueOption {
    userId: string;
    sid: string;
}

export interface EmailParameter {
    Body: string;
    FromEmail: string;
    ToEmails: string;
    Subject: string;
    FromName: string;
    CcEmails: string;
    BccEmails: string;
}

const SELECT_USER_LIMIT = 10;
const DEFAULT_SELECTED_COMPANY = {
    label: 'Select...',
    value: '*'
};

const ROLES = ['User', 'Admin'] as const;

interface UserVerifyExportType {
    // No: number;
    Company: string;
    User: string;
    'User Type': string;
    'First Name': string;
    'Last Name': string;
    Email: string;
    'Workflow Email': string;
    'F1 Employee Code': string;
    'F1 First Name': string;
    'F1 Last Name': string;
    'F1 Email': string;
    Status: string;
}

const TAB_VALUES = new Map<TabLabel, string>([
    ['Pending', 'pending'],
    ['Approved', 'active'],
    ['Rejected', 'rejected']
]);

function UserVerificationViewModel() {
    const loader = useLoader();
    const { userCompanyStore, companyMasterStore } = useStore();
    const { userRole, citizenId, message, currentAdminCompany, email } = userCompanyStore;
    const { userCompanyMapping, userCompanyMappingOptions } = userCompanyStore;
    const companyMaster = companyMasterStore.companyMaster;

    const displayRoles = useMemo(
        () => ROLES.slice(0, userRole === 'Super Admin' ? 2 : 1),
        [userRole]
    );

    const [selectedCompany, setSelectedCompany] = useState<MultiValue<Option>>([]);
    const [selectedUsers, setSelectedUsers] = useState<MultiValue<Option>>([]);
    const [companyOptions, setCompanyOptions] = useState<Option[]>([]);
    const [activeTab, setActiveTab] = useState<TabLabel>('Approved');
    const [showModal, setShowModal] = useState(false);
    const [isSCG, setIsSCG] = useLocalStorage('IS_SCG', false);

    // Searching
    const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
    const [searchUser, setSearchUser] = useState('');
    const [advanceSearchUser, setAdvanceSearchUser] = useState<Option[]>([]);

    const deferredUserCompanyMapping = useDeferredValue(userCompanyMapping);
    const deferredActiveTab = useDeferredValue(activeTab);
    const deferredSelectedCompany = useDeferredValue(selectedCompany);
    const deferredIsAdvanceSearch = useDeferredValue(isAdvanceSearch);
    const deferredSearchUser = useDeferredValue(searchUser);
    const deferredAdvanceSearchUser = useDeferredValue(advanceSearchUser);
    const filteredUsers = useMemo(() => {
        if (deferredUserCompanyMapping.length === 0) {
            return [];
        }

        return deferredUserCompanyMapping.filter(user => {
            const isCompanyMatched =
                deferredSelectedCompany.length != 0
                    ? deferredSelectedCompany.filter(company => company.value === user.sid).length >
                      0
                        ? true
                        : false
                    : true;

            const activeTabValue = TAB_VALUES.get(deferredActiveTab);
            const isUserMatched =
                activeTabValue === 'active'
                    ? user.status === 'active' || user.status === 'inactive'
                    : user.status === activeTabValue;

            const isSearchUser = !deferredIsAdvanceSearch
                ? deferredSearchUser !== ''
                    ? user.citizen_id
                          .toLocaleLowerCase()
                          .includes(deferredSearchUser.toLocaleLowerCase()) ||
                      (user.email !== null &&
                          user.email
                              .toLocaleLowerCase()
                              .includes(deferredSearchUser.toLocaleLowerCase())) ||
                      (user.first_name !== null &&
                          user.first_name
                              .toLocaleLowerCase()
                              .includes(deferredSearchUser.toLocaleLowerCase())) ||
                      (user.last_name !== null &&
                          user.last_name
                              .toLocaleLowerCase()
                              .includes(deferredSearchUser.toLocaleLowerCase())) ||
                      (user.type !== null &&
                          user.type
                              .toLocaleLowerCase()
                              .includes(deferredSearchUser.toLocaleLowerCase()))
                    : true
                : deferredAdvanceSearchUser.length !== 0
                ? deferredAdvanceSearchUser.some(
                      searchUser =>
                          user.citizen_id
                              .toLocaleLowerCase()
                              .includes((searchUser.value as string).toLocaleLowerCase() ?? '') ||
                          (user.email !== null &&
                              user.email
                                  .toLocaleLowerCase()
                                  .includes(
                                      (searchUser.value as string).toLocaleLowerCase() ?? ''
                                  )) ||
                          (user.first_name !== null &&
                              user.first_name
                                  .toLocaleLowerCase()
                                  .includes(
                                      (searchUser.value as string).toLocaleLowerCase() ?? ''
                                  )) ||
                          (user.last_name !== null &&
                              user.last_name
                                  .toLocaleLowerCase()
                                  .includes(
                                      (searchUser.value as string).toLocaleLowerCase() ?? ''
                                  )) ||
                          (user.type !== null &&
                              user.type
                                  .toLocaleLowerCase()
                                  .includes((searchUser.value as string).toLocaleLowerCase() ?? ''))
                  )
                : true;

            return isCompanyMatched && isUserMatched && isSearchUser;
        });
    }, [
        deferredUserCompanyMapping,
        deferredActiveTab,
        deferredSelectedCompany,
        deferredIsAdvanceSearch,
        deferredSearchUser,
        deferredAdvanceSearchUser
    ]);

    useEffect(() => {
        fetchIsSCG();
    }, []);

    const fetchIsSCG = async () => {
        const data = (await axios.get('/v1/link-sid')).data;
        setIsSCG(data.link_sid === 'SCG');
    };

    useEffect(() => {
        if (!userRole || userRole === 'User') {
            return;
        }

        (async () => {
            try {
                loader.show();
                await Promise.all([fetchAllUserCompany(), fetchCompanyMaster()]);
            } catch {
            } finally {
                loader.hide();
            }
        })();
    }, [userRole]);

    useEffect(() => {
        if (userRole === 'User') {
            return;
        }

        //console.log(selectedCompany)

        //if (userRole === 'Admin'
        //    && companyOptions.length === 0
        //    && selectedCompany.length > 0) {
        //    const companySelectOption: Option[] = [/*{ label: 'กรุณาเลือก Company...', value: '*' }*/];

        //    selectedCompany.forEach(company => {
        //        companySelectOption.push({
        //            label: company.label,
        //            value: company.value
        //        } as Option);
        //    });

        //    console.log(companySelectOption)

        //    setCompanyOptions(companySelectOption);
        //}

        //if (userRole !== 'Super Admin') {
        //    return;
        //}

        if (companyOptions.length === 0 && companyMaster && companyMaster.length > 0) {
            const companySelectOption: Option[] = [
                /*{ label: 'กรุณาเลือก Company...', value: '*' }*/
            ];

            companyMaster.forEach(company => {
                companySelectOption.push({
                    label: company.company_name,
                    value: company.sid
                } as Option);
            });

            setCompanyOptions(companySelectOption);
        }
    }, [companyMaster, companyOptions]);

    useEffect(() => {
        if (userRole === '' || userRole === 'User' || currentAdminCompany.length === 0) {
            return;
        }

        //if (currentAdminCompany && currentAdminCompany.length > 0) {
        var companyArray: Option[] = [];
        currentAdminCompany.forEach(item => {
            //if (citizenId == item.citizen_id && (item.type === 'admin' || item.type === 'super_admin')) {
            companyArray.push({
                label: item.company_name,
                value: item.sid
            } as Option);
            //}
        });
        const selectedCompany = companyArray.map(item => ({
            value: item.value,
            label: item.label
        }));

        //console.log(selectedCompany)

        if (userRole === 'Admin') {
            setCompanyOptions(companyArray);
            onSelectCompany(selectedCompany);
        }

        //setSelectedCompany(selectedCompany);
        //onSelectCompany(selectedCompany);
        //}

        //const selectedCompany = [{
        //    label: currentAdminCompany.company_name,
        //    value: currentAdminCompany.sid
        //}];

        //var companyArray: Option[] = [];
        //userCompanyMapping.forEach(item => {
        //    if (citizenId == item.citizen_id && (item.type === 'admin' || item.type === 'super_admin')) {
        //        companyArray.push({
        //            label: item.company_name,
        //            value: item.sid
        //        } as Option);
        //    }
        //});

        //const selectedCompany = companyArray.map(item => ({ value: item.value, label: item.label }));

        //console.log(selectedCompany)

        //setSelectedCompany(selectedCompany);
    }, [userRole, currentAdminCompany]);

    // useEffect(() => {
    //     //console.log(userCompanyMapping)
    //     if (userCompanyMapping.length === 0 || selectedCompany.includes(DEFAULT_SELECTED_COMPANY)) {
    //         return;
    //     }

    //     onSelectCompany(selectedCompany);
    // }, [userCompanyMapping, selectedCompany]);

    const fetchAllUserCompany = async () => {
        try {
            loader.show();
            await userCompanyStore.fetchAllUserCompany('', '', '');
        } catch (error) {
        } finally {
            loader.hide();
        }
    };

    const fetchCompanyMaster = async () => {
        await companyMasterStore.fetchCompanyMasterStore('', 'active');
    };

    const onSelectCompany = (company: MultiValue<Option>) => {
        setSelectedCompany(company ?? [DEFAULT_SELECTED_COMPANY]);
    };

    const toggleIsAdvanceSearchUser = () => {
        setIsAdvanceSearch(!isAdvanceSearch);
    };

    const handleChangeAdvanceSearchUser = (users: Option[]) => {
        const advanceSearchUser = users.reduce<Option[]>((accumulate, current) => {
            const userOptions = (current.value as string)
                .split(' ')
                .map<Option>(email => ({ label: email, value: email }));

            accumulate.push(...userOptions);

            return accumulate;
        }, []);

        setAdvanceSearchUser(advanceSearchUser);
    };

    const onSelectUser = (users: MultiValue<Option>) => {
        if (users.length > SELECT_USER_LIMIT) {
            fireSwal('Error!', 'Cannot select user more than 10.', 'error');

            return;
        }

        setSelectedUsers(users);
    };

    const handleClickAddUser = useCallback(
        async (userType: (typeof ROLES)[number]) => {
            try {
                loader.show();

                if (selectedCompany.includes(DEFAULT_SELECTED_COMPANY)) {
                    fireSwal('Error!', 'กรุณาเลือก Company', 'error');
                    loader.hide();

                    return;
                }

                if (selectedUsers.length === 0) {
                    fireSwal('Error!', 'กรุณากรอก User', 'error');
                    loader.hide();

                    return;
                }

                const prepareAddUsers = selectedUsers.reduce<AddUserRequest[]>(
                    (accumulate, user) => {
                        if ((user.value as string).trim() === '') {
                            return accumulate;
                        }

                        selectedCompany.forEach(company => {
                            accumulate.push({
                                sid: company.value as string,
                                citizen_id: user.value as string,
                                type: userType.toLowerCase()
                            });
                        });

                        return accumulate;
                    },
                    []
                );

                addUsers(prepareAddUsers);

                await fireSwal('Success!', 'เพิ่ม User สำเร็จ', 'success');

                window.location.reload();
            } catch (error) {
                fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
            }
        },
        [selectedUsers, selectedCompany]
    );

    const onClickEditUserStatusOnce = async (
        sid: string,
        citizenId: string,
        status: 'approve' | 'active' | 'rejected' | 'inactive'
    ) => {
        try {
            loader.show();

            await userCompanyStore.featchUpdateUsersStatus(status, sid, [citizenId]);
            userCompanyStore.updateUserStatusUI(citizenId, status);

            fireSwal('Success!', 'Update user status success.', 'success');
        } catch (error) {
            if (error instanceof Error) {
                fireSwal('Error!', error.message, 'error');
            }
        } finally {
            fetchAllUserCompany();
            loader.hide();
        }
    };

    const sendEmail = async (props: EmailParameter) => {
        await fetch(`/v1/user/verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            },
            body: JSON.stringify(props)
        });
    };

    const searchPendingUser = (userOptions: FilterOptionOption<Option>, inputUser: string) => {
        if (inputUser === '') return false;

        if (userOptions.label.includes(inputUser)) return true;

        return false;
    };

    const handleInviteSuccess = async (selectedCompany: Option[], selectedEmails: Option[]) => {
        await fetchAllUserCompany();

        // Search recently invited users
        onSelectCompany(selectedCompany);

        if (selectedEmails.length > 1) {
            setSearchUser('');

            setIsAdvanceSearch(true);
            setAdvanceSearchUser(selectedEmails);
        } else {
            setIsAdvanceSearch(false);
            setAdvanceSearchUser([]);

            setSearchUser(selectedEmails[0].value.toString());
        }
    };

    const handleSubmitEditUser = async (editedUser: UserCompany) => {
        console.log('UserVerificationViewModel: edited user', editedUser);

        await axios.put('/v1/user/company/info', editedUser);
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        },
        []
    );

    const { isOpen: showModalInvite, onToggle: toggleShowModalInvite } = useDisclosure();

    const exportExcel = () => {
        try {
            loader.show();

            if (userCompanyMapping.length === 0) {
                throw new Error('No user for export.');
            }

            // Prepare Data
            let exportUsers: UserVerifyExportType[] = [];
            if (filteredUsers.length > 0 && !selectedCompany.includes(DEFAULT_SELECTED_COMPANY)) {
                exportUsers = _prepareExportData(filteredUsers);
            } else {
                exportUsers = _prepareExportData(userCompanyMapping);
            }

            // Export Excel
            const date = new Date();
            const fileName = 'UserVerification_' + date.getTime().toString() + '.xlsx';
            const worksheet = XLSX.utils.json_to_sheet(exportUsers);
            const workbook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workbook, worksheet, 'User Verification');
            XLSX.writeFile(workbook, fileName);
        } catch (error) {
            if (error instanceof Error) {
                fireSwal('Error!', error.message, 'error');
            }
        } finally {
            loader.hide();
        }
    };

    const _prepareExportData = (users: UserCompany[]) => {
        const exportData = users
            .reduce<UserVerifyExportType[]>((accumulate, user, index) => {
                accumulate.push({
                    // No: index + 1,
                    Company: user.company_name,
                    User: user.citizen_id,
                    'First Name': user.first_name,
                    'Last Name': user.last_name,
                    Email: user.email,
                    'Workflow Email': user.workflow_email,
                    'F1 Employee Code': user.f1_employee_code,
                    'F1 First Name': user.f1_first_name,
                    'F1 Last Name': user.f1_last_name,
                    'F1 Email': user.f1_email,
                    'User Type': user.type,
                    Status: user.status
                });

                return accumulate;
            }, [])
            .sort((a, b) => {
                switch (b.Status) {
                    case 'active':
                    case 'inactive': {
                        return -1;
                    }
                    case 'pending': {
                        return 0;
                    }
                    default: {
                        // rejected
                        return 1;
                    }
                }
            })
            .reverse()
            .sort((a, b) => (a.Company < b.Company ? -1 : a.Company > b.Company ? 1 : 0));

        return exportData;
    };

    return {
        isSCG,
        email,
        userRole,
        showModal,
        isAdvanceSearch,
        searchUser,
        advanceSearchUser,
        displayRoles,
        filteredUsers,
        selectedCompany,
        companyOptions,
        activeTab,
        selectedUsers,
        userCompanyMapping,
        showModalInvite,
        onSelectCompany,
        setSelectedCompany,
        toggleIsAdvanceSearchUser,
        handleChangeAdvanceSearchUser,
        setActiveTab,
        searchPendingUser,
        onSelectUser,
        onClickEditUserStatusOnce,
        handleClickAddUser,
        toggleShowModalInvite,
        sendEmail,
        setShowModal,
        exportExcel,
        setSearchUser,
        handleInviteSuccess,
        handleSubmitEditUser
    };
}

export default UserVerificationViewModel;
