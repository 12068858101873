import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiFillEye, AiFillPlayCircle, AiOutlineComment, AiOutlineShareAlt } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { ImHammer2 } from 'react-icons/im';
import { IoArrowDownCircle, IoArrowUpCircle, IoCloseCircle } from 'react-icons/io5';
import { PiUserCircleFill } from 'react-icons/pi';
import { twMerge } from 'tailwind-merge';

import { Gifs } from 'assets';
import { TaskActivity } from 'data/link/schemas';
import { Option } from 'models/taskGroup/TaskGroup';
import { Attachment, FileInfo, LikedTask, TaskStack, TaskStore } from 'models/taskStore/Task';
import { BsHandThumbsUp, BsHandThumbsUpFill } from 'react-icons/bs';

// ? app-pool-task-store
type Props = {
    taskType: string;
    userType?: string;
    task: TaskStore;
    liked?: Map<string, LikedTask>;
    taskStack: TaskStack[];
    taskGroupList?: Option[];
    className?: string;
    onClickImage?: (index: number, imageUris: string[]) => void;
    onClickReadMore?: (
        task: TaskStore,
        imageUris?: string[],
        options?: { scrollToComment?: boolean }
    ) => void;
    onClickCloseTask?: (task: TaskStore) => void;
    onClickReleaseTask?: (task: TaskStore) => void;
    onClickCancelTask?: (task: TaskStore) => void;
    onClickEditTask?: (task: TaskStore) => void;
    onHandleEventElement?: (task: TaskStore, eventType: string) => void;
    onClickShowUserEvent?: (type: string, username: string[]) => void;
    onClickShowCommentEvent?: (keyId: string) => void;
};

function PostActivity(props: Props) {
    const {
        taskType = '',
        userType = '',
        task,
        liked,
        taskStack,
        taskGroupList,
        className = '',
        onClickImage = () => void 0,
        onClickReadMore = () => void 0,
        onClickCloseTask = () => void 0,
        onClickReleaseTask = () => void 0,
        onClickCancelTask = () => void 0,
        onClickEditTask = () => void 0,
        onHandleEventElement = () => void 0,
        onClickShowUserEvent = () => void 0,
        onClickShowCommentEvent = () => void 0
    } = props;

    const {
        is_release,
        subject,
        description: detail,
        created_by,
        updated_on,
        attachment,
        group_id,
        mode,
        poster_subject,
        poster_background
    } = task; // ? app-pool-task-store
    const updatedOnDate = new Date(Date.parse(updated_on));

    const detailElementRef = useRef<HTMLParagraphElement>(null);

    const [fetching, setFetching] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    const [taskActivity, setTaskActivity] = useState<TaskActivity>();

    // ? app-pool-task-store
    const [profilePic, setProfilePic] = useState<FileInfo>();
    const [images, files] = useMemo(() => {
        if (!task.attachment) {
            return [[], []];
        }

        const parseAttachment: Attachment = attachment ? JSON.parse(attachment) : '';

        const { file_list, profile_pic } = parseAttachment;

        if (profile_pic && profile_pic.file_name && profile_pic.file_path) {
            setProfilePic(parseAttachment.profile_pic);
        }

        return file_list.reduce(
            (accumulate, current) => {
                if (
                    current.file_path.includes('.jpg') ||
                    current.file_path.includes('.png') ||
                    current.file_path.includes('.jpeg') ||
                    current.file_path.includes('.mp4')
                ) {
                    accumulate[0].push(current.file_path);
                } else {
                    accumulate[1].push(current.file_path);
                }

                return accumulate;
            },
            [[], []] as [string[], string[]]
        );
    }, [attachment]);

    const group = useMemo(() => {
        if (!taskGroupList || !task.group_id) {
            return;
        }

        return taskGroupList.find(item => item.value === task.group_id);
    }, [taskGroupList]);

    // Analyze detail lines
    useEffect(() => {
        const element = detailElementRef.current;

        if (!element) {
            return;
        }

        // Computed style needed
        const styles = window.getComputedStyle(element);
        const lineHeight = parseInt(styles.lineHeight);

        // Exit function if lines less than or equal 3 compare to client height (box size)
        if (element.clientHeight <= lineHeight * 3) {
            return;
        }

        // Clamp line if more than 3 lines
        element.classList.add('line-clamp-3');
        setShowReadMore(true);
    }, []);

    const handleClickOpenFile = useCallback(
        (url: string) =>
            setTimeout(() => {
                window.open(url, '_blank');
            }),
        []
    );

    const stripHtmlContentToString = (htmlContent: string) => {
        const parser = new DOMParser();
        const parseString = parser.parseFromString(htmlContent, 'text/html');
        return parseString.body.textContent || '';
    };

    const background = poster_background?.includes('https')
        ? `url('${poster_background}')`
        : poster_background;

    const imageBackgroundStyle = {
        background: `center no-repeat ${background}`,
        backgroundSize: '100%'
    };

    const handleEventElement = (eventType: string) => {
        onHandleEventElement(task, eventType);
    };
    const [userEventStatus, setUserEventStatus] = useState<string[]>();
    const tooltipOffsetLef = useRef(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const matchingStack = taskStack?.find(item => item.key_id === task.key_id);

    const getStackDataValue = (
        key: keyof Pick<TaskStack, 'like' | 'comment' | 'share' | 'view'>
    ) => {
        if (!matchingStack) {
            return 0;
        }

        return matchingStack[key];
    };

    const onMouseClickEventStatus = (typeStatus: string) => {
        switch (typeStatus) {
            case 'like':
                onClickShowUserEvent(typeStatus, matchingStack?.like_username ?? []);
                break;
            case 'comment':
                onClickShowCommentEvent(task.key_id);
                break;
            case 'view':
                onClickShowUserEvent(typeStatus, matchingStack?.view_username ?? []);
                break;
        }
    };

    const onMouseEnter = useCallback(
        (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
            key: keyof Pick<
                TaskStack,
                'like_username' | 'comment_username' | 'view_username' | 'share_username'
            >
        ) => {
            tooltipOffsetLef.current = (event.target as HTMLDivElement).offsetLeft;
            setUserEventStatus(prevState => {
                if (!matchingStack) {
                    return prevState;
                }

                return matchingStack[key];
            });
            setShowTooltip(true);
        },
        [matchingStack, setUserEventStatus, setShowTooltip]
    );

    const onMouseLeave = useCallback(() => {
        tooltipOffsetLef.current = 0;
        setUserEventStatus(undefined);
        setShowTooltip(false);
    }, [setShowTooltip]);

    const isLiked = liked && liked.get(task.key_id);
    const ThumbIcon = isLiked ? BsHandThumbsUpFill : BsHandThumbsUp;

    return (
        <div
            className={twMerge(
                'rounded-md bg-white p-2 drop-shadow-md transition-transform hover:scale-[1.02]',
                className,
                is_release && userType === 'super_admin' ? 'border-2 border-green-300' : 'border'
            )}
        >
            <div className="flex w-full items-center justify-between">
                <p className="flex-1 pl-4 text-primary-900">
                    {`${updatedOnDate.getDate()}/${
                        updatedOnDate.getMonth() + 1
                    }/${updatedOnDate.getFullYear()}`}{' '}
                    {`${updatedOnDate.toLocaleTimeString('th-TH')} น.`}
                </p>

                {userType === 'super_admin' && (
                    <div className="ml-auto flex rounded border [&>span:not(:first-child)]:border-l">
                        <span
                            className="hidden cursor-pointer px-2 py-0.5 transition-all hover:scale-150 hover:text-amber-800"
                            onClick={() => onClickCloseTask(task)}
                        >
                            <ImHammer2 size={20} />
                        </span>

                        <span
                            className={twMerge(
                                'cursor-pointer px-2 py-0.5 transition-all hover:shadow-inner',
                                is_release
                                    ? 'hover:text-rose-600 hover:shadow-red-400'
                                    : 'hover:text-green-600 hover:shadow-green-400'
                            )}
                            onClick={() => onClickReleaseTask(task)}
                        >
                            {is_release ? (
                                <IoArrowDownCircle
                                    size={20}
                                    color="red"
                                />
                            ) : (
                                <IoArrowUpCircle
                                    size={20}
                                    color="green"
                                />
                            )}
                        </span>

                        <span
                            className="cursor-pointer px-2 py-0.5 transition-all hover:text-rose-600 hover:shadow-inner hover:shadow-red-400"
                            onClick={() => onClickCancelTask(task)}
                        >
                            <IoCloseCircle size={20} />
                        </span>
                        <span
                            className="cursor-pointer px-2 py-0.5 transition-all hover:text-amber-600 hover:shadow-inner hover:shadow-amber-400"
                            onClick={() => onClickEditTask(task)}
                        >
                            <FaEdit size={20} />
                        </span>
                    </div>
                )}
            </div>
            {group && (
                <div className="px-4">
                    <p>
                        {group?.value} : {group?.label}
                    </p>
                </div>
            )}

            {mode === 'poster' ? (
                <div className="flex h-[350px] flex-col gap-2 p-2">
                    <div
                        className="flex h-[280px] flex-col justify-start rounded-lg"
                        style={imageBackgroundStyle}
                    >
                        <div
                            ref={detailElementRef}
                            className="mt-3 flex flex-1 flex-col overflow-hidden whitespace-pre-wrap break-words px-3 text-sm text-neutral-500"
                            dangerouslySetInnerHTML={{
                                __html: poster_subject ? poster_subject : ''
                            }}
                        />
                        {showReadMore && (
                            <span
                                onClick={() => onClickReadMore(task, images)}
                                className="z-10 mt-2 cursor-pointer px-3 text-[12px] font-medium underline"
                            >
                                Read more
                            </span>
                        )}
                        {taskType === 'task' && (
                            <div className="mt-auto flex justify-end">
                                <div className="h-[60px] w-[60px] rounded-full bg-white">
                                    {profilePic ? (
                                        <img
                                            key={profilePic.file_name}
                                            src={profilePic.file_path}
                                            className="h-[60px] w-[60px] rounded-full p-1.5"
                                        />
                                    ) : (
                                        <PiUserCircleFill
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                color: '#A1A1A1'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="h-[50px] flex-1 flex-col justify-end border-t" />
                </div>
            ) : (
                <div className="flex h-[350px] flex-col px-4 py-2">
                    <div className="flex h-[175px] flex-col justify-start border-b">
                        {/* Subject */}
                        <div className="flex items-center">
                            {fetching && (
                                <img
                                    className="inline h-4 w-4"
                                    src={Gifs.F1_LOADINGGIF}
                                />
                            )}
                            <p className="flex-1 text-lg font-medium leading-tight text-neutral-900">
                                <span
                                    className={twMerge(
                                        'line-clamp-1 inline-block transition-transform',
                                        fetching ? 'translate-x-2' : ''
                                    )}
                                >
                                    {subject}
                                </span>
                            </p>
                        </div>

                        {/* Description */}
                        <span
                            ref={detailElementRef}
                            className="mt-3 overflow-hidden whitespace-pre-wrap break-words text-sm text-neutral-500"
                            dangerouslySetInnerHTML={{ __html: detail }}
                        />
                        {showReadMore && (
                            <span
                                onClick={() => onClickReadMore(task, images)}
                                className="z-10 mt-2 cursor-pointer text-[12px] font-medium underline"
                            >
                                Read more
                            </span>
                        )}
                    </div>

                    {/* Footer */}
                    <div className="-mt-8 h-[175px] flex-1 flex-col justify-end">
                        {taskType === 'task' && (
                            <div className="flex justify-end">
                                <div className="bg h-[60px] w-[60px] rounded-full bg-white">
                                    {profilePic ? (
                                        <img
                                            key={profilePic.file_name}
                                            src={profilePic.file_path}
                                            className="h-[60px] w-[60px] rounded-full p-1.5"
                                        />
                                    ) : (
                                        <PiUserCircleFill
                                            style={{
                                                width: '60px',
                                                height: '60px',
                                                color: '#A1A1A1'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Images */}
                        <div className="mt-3 flex gap-2 overflow-x-auto">
                            {images.map((imageUri, index) => {
                                if (imageUri.includes('.mp4')) {
                                    return (
                                        <div
                                            key={index}
                                            className="relative cursor-pointer"
                                        >
                                            <video
                                                className="w-26 h-24"
                                                // poster={Icons.PLAY_ICON}
                                                autoPlay={false}
                                                onClick={() => onClickImage(index, images)}
                                            >
                                                <source
                                                    src={imageUri}
                                                    type="video/mp4"
                                                />
                                            </video>
                                            <AiFillPlayCircle className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 text-white opacity-80" />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <img
                                            key={index}
                                            src={imageUri}
                                            alt={`Image-${index}`}
                                            className="h-20 w-20 cursor-pointer rounded-md"
                                            onClick={() => onClickImage(index, images)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            )}

            <div className="relative flex items-center px-4">
                {showTooltip &&
                    userEventStatus &&
                    userEventStatus.length > 0 &&
                    !userEventStatus.some(username => !username) && (
                        <ul
                            className="pointer-events-none absolute bottom-full rounded-md bg-black/50 p-1.5"
                            style={{ left: tooltipOffsetLef.current }}
                        >
                            {userEventStatus.map(username => (
                                <li
                                    key={username}
                                    className="text-white"
                                >
                                    {username}
                                </li>
                            ))}
                        </ul>
                    )}

                <div
                    onMouseEnter={event => onMouseEnter(event, 'like_username')}
                    onMouseLeave={onMouseLeave}
                    className={twMerge('flex-1', isLiked && 'text-primary')}
                >
                    <div className="group flex cursor-pointer items-center gap-1.5">
                        <ThumbIcon
                            size={20}
                            onClick={() => handleEventElement(isLiked ? 'unlike' : 'like')}
                        />
                        <span
                            onClick={() => onMouseClickEventStatus('like')}
                            className="group-hover:underline"
                        >
                            {getStackDataValue('like')}
                        </span>
                    </div>
                </div>
                <div
                    onMouseEnter={event => onMouseEnter(event, 'comment_username')}
                    onMouseLeave={onMouseLeave}
                    className="flex-1"
                >
                    <div
                        className="group flex cursor-pointer items-center gap-1.5"
                        onClick={() => {
                            onMouseClickEventStatus('comment');
                            onClickReadMore(task, images, { scrollToComment: true });
                        }}
                    >
                        <AiOutlineComment size={20} />
                        <span className="group-hover:underline">
                            {getStackDataValue('comment')}
                        </span>
                    </div>
                </div>
                <div
                    onMouseEnter={event => onMouseEnter(event, 'view_username')}
                    onMouseLeave={onMouseLeave}
                    className="flex-1"
                >
                    <div
                        className="group flex cursor-pointer items-center gap-1.5"
                        onClick={() => onMouseClickEventStatus('view')}
                    >
                        <AiFillEye
                            size={20}
                            className="pointer-events-none"
                        />
                        <span className="group-hover:underline">{getStackDataValue('view')}</span>
                    </div>
                </div>
                <div
                    onMouseEnter={event => onMouseEnter(event, 'share_username')}
                    onMouseLeave={onMouseLeave}
                    onClick={() => handleEventElement('share')}
                >
                    <div className="group flex cursor-pointer items-center gap-1.5">
                        <AiOutlineShareAlt size={20} />
                        <span className="group-hover:underline">{getStackDataValue('share')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(PostActivity);
