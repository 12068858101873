import { useCallback, useMemo, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useBoolean
} from '@chakra-ui/react';

import { Button, Creatable, Input, Label, RadioButtonGroup } from 'common/materials';
import { useStore, useLoader } from 'common/hooks';
import { IAddInviteRequest } from 'data/user/schemas';
import { addInvite } from 'data/user/repositories/userCompanyRepository';
import { UserCompany } from 'models/user/UserCompany';

import SelectUserModal from './SelectUserModal';

interface Option {
    readonly label: string;
    readonly value: string;
}

type Props = {
    isSCG: boolean;
    isOpen: boolean;
    email: string;
    role: string;
    companyOptions: Option[];
    onClose: () => void;
    onInviteSuccess?: (selectedCompany: Option[], selectedEmails: Option[]) => void;
};

const MODE_INVITE = 'INVITE';
const MODE_ADD = 'ADD';

const ROLES = ['User', 'Admin'] as const;

function UserVerificationInviteModal(props: Props) {
    const { isSCG, isOpen, email, role, companyOptions, onClose, onInviteSuccess } = props;

    const screenLoader = useLoader();

    const [showSelectUserModal, setShowSelectUserModal] = useBoolean();

    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [useWorkflow, setUseWorkflow] = useState(false);
    const [useDMS, setUseDMS] = useState(false);
    const [emailOptions, setEmailOptions] = useState<Option[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<Option[]>([]);
    const [selectedRole, setSelectedRole] = useState<string>('User');
    const { userCompanyStore } = useStore();
    const { userRole, currentAdminCompany, userCompanyMapping, userCompanyMappingOptions } =
        userCompanyStore;

    const displayRoles = useMemo(() => ROLES.slice(0, role === 'Super Admin' ? 2 : 1), [role]);

    useEffect(() => {
        if (userRole === '' || !currentAdminCompany) {
            return;
        }

        //const selectedCompany = [{
        //    label: currentAdminCompany.company_name,
        //    value: currentAdminCompany[0].sid
        //}];
        var companyArray: Option[] = [];
        currentAdminCompany.forEach(item => {
            //if (citizenId == item.citizen_id && (item.type === 'admin' || item.type === 'super_admin')) {
            companyArray.push({
                label: item.company_name,
                value: item.sid
            } as Option);
            //}
        });

        if (userRole === 'Admin') {
            setSelectedCompany(companyArray);
        }
    }, [userRole]);

    const handleChangeEmailOptions = (inputEmails: Option[]) => {
        const emailOptions = inputEmails.reduce<Option[]>((accumulate, current) => {
            const emails = current.value
                .split(' ')
                .filter(email => email)
                .map<Option>(email => ({ label: email.trim(), value: email.trim() }));

            accumulate.push(...emails);

            return accumulate;
        }, []);

        setEmailOptions(emailOptions);
    };

    const handleSelectUser = useCallback((user: UserCompany) => {
        setUsername(user.citizen_id);
        setUserEmail(user.email);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        // setUseWorkflow(user.workflow_status === 'true');
        // setUseDMS(user.dms_status === 'true');
    }, []);

    const handleClickSendInvite = async () => {
        //const selectedSid = selectedCompany?.value;
        if (!selectedCompany) {
            fireSwal('Error!', 'กรุณาเลือก Company', 'error');

            return;
        }

        if (emailOptions.length < 1) {
            fireSwal('Error!', 'กรุณาระบุ email', 'error');

            return;
        }

        try {
            screenLoader.show();

            const prepareInviteData: IAddInviteRequest[] = [];
            selectedCompany.forEach(selectedSid => {
                prepareInviteData.push(
                    ...emailOptions.map<IAddInviteRequest>(
                        emailOption =>
                            ({
                                mode: MODE_INVITE,
                                citizen_id: emailOption.value,
                                sid: selectedSid.value,
                                type: selectedRole.toLowerCase()
                            }) as unknown as IAddInviteRequest
                    )
                );
            });

            console.log(prepareInviteData);

            const [error] = await addInvite(prepareInviteData);
            if (error) {
                throw error;
            }

            fireSwal('Success!', 'เพิ่ม User สำเร็จ', 'success').then(() => {
                onClose();
                onInviteSuccess && onInviteSuccess(selectedCompany, emailOptions);
            });
        } catch (error) {
            console.log(error);
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    };

    const handleClickAdd = async () => {
        //const selectedSid = selectedCompany;
        //console.log(selectedCompany)
        if (!selectedCompany
            || selectedCompany.length === 0) {
            fireSwal('Error!', 'กรุณาเลือก Company', 'error');

            return;
        }

        if (!username
            || !username.trim()) {
            fireSwal('Error!', 'กรุณาระบุ Username', 'error');

            return;
        }

        if (!firstName
            || !firstName.trim()) {
            fireSwal('Error!', 'กรุณาระบุ First Name', 'error');

            return;
        }

        if (!lastName
            || !lastName.trim()) {
            fireSwal('Error!', 'กรุณาระบุ Last Name', 'error');

            return;
        }

        if (!userEmail
            || !userEmail.trim()) {
            fireSwal('Error!', 'กรุณาระบุ Email', 'error');

            return;
        }

        try {
            screenLoader.show();

            const prepareInviteData = selectedCompany.map<IAddInviteRequest>(company => ({
                mode: MODE_ADD,
                citizen_id: username,
                sid: company.value,
                first_name: firstName,
                last_name: lastName,
                email: userEmail,
                use_workflow: useWorkflow,
                use_dms: useDMS,
                type: selectedRole.toLowerCase()
            }));

            //console.log(prepareInviteData);

            const [error] = await addInvite(prepareInviteData);
            if (error) {
                throw error;
            }

            fireSwal('Success!', 'เพิ่ม User สำเร็จ', 'success').then(() => {
                onClose();
                onInviteSuccess && onInviteSuccess(selectedCompany, [{ label: '', value: username }]);
            });
        } catch (error) {
            console.log(error);
            fireSwal('Error!', 'เกิดข้อผิดพลาด', 'error');
        } finally {
            screenLoader.hide();
        }
    };

    const fireSwal = useCallback(
        (title: string, message: string, icon: Parameters<typeof Swal.fire>[2]) => {
            return Swal.fire({
                title,
                text: message,
                icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        },
        []
    );

    return (
        <Modal
            isCentered
            size="5xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent className="min-w-[95dvw]">
                <ModalHeader className="text-2xl font-medium">User Verification</ModalHeader>
                <ModalBody>
                    <div className="bg-whtie rounded-md border px-4 py-2">
                        <p className="text-lg font-semibold">
                            {isSCG ? 'Add User' : 'Invite User'}
                        </p>
                        <div className="flex flex-col gap-2 px-2 py-2">
                            <Label>Email</Label>
                            <Input
                                disabled
                                placeholder={email}
                            />
                            <Label>Role</Label>
                            <Input
                                disabled
                                placeholder={role}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-6">
                        <span>{isSCG ? 'Add as' : 'Invite as'}</span>
                        <RadioButtonGroup
                            values={displayRoles}
                            defaultSelectedValue={selectedRole}
                            onChange={value => setSelectedRole(value)}
                        />
                        <Label>Company</Label>
                        <Creatable
                            className="w-full"
                            isMulti={true}
                            onChange={value => setSelectedCompany(value as any)}
                            options={companyOptions}
                            value={selectedCompany}
                            isDisabled={role === 'User'}
                        />
                        {isSCG ? (
                            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                                <div className="flex flex-col gap-2">
                                    <Label>Username</Label>
                                    <div className="flex items-center gap-2">
                                        <Input
                                            type="text"
                                            value={username}
                                            autoFocus={true}
                                            placeholder="username"
                                            onChange={event => setUsername(event.target.value)}
                                            className="flex-1"
                                        />
                                        <Button
                                            outline
                                            onClick={setShowSelectUserModal.on}
                                            className="border-primary-900 text-sm text-primary-900"
                                        >
                                            Select from Existing
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Label>Email</Label>
                                    <Input
                                        type="text"
                                        value={userEmail}
                                        autoFocus={false}
                                        placeholder="email"
                                        onChange={event => setUserEmail(event.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Label>First Name</Label>
                                    <Input
                                        type="text"
                                        value={firstName}
                                        autoFocus={false}
                                        placeholder="first name"
                                        onChange={event => setFirstName(event.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Label>Last Name</Label>
                                    <Input
                                        type="text"
                                        value={lastName}
                                        autoFocus={false}
                                        placeholder="last name"
                                        onChange={event => setLastName(event.target.value)}
                                    />
                                </div>
                                {/*<div className="flex flex-col gap-2">*/}
                                {/*    <div className="flex items-center gap-2">*/}
                                {/*        <Input*/}
                                {/*            id="use-workflow"*/}
                                {/*            type="checkbox"*/}
                                {/*            checked={useWorkflow}*/}
                                {/*            autoFocus={false}*/}
                                {/*            placeholder="email"*/}
                                {/*            className="h-4 w-4"*/}
                                {/*            onChange={event => setUseWorkflow(event.target.checked)}*/}
                                {/*        />*/}
                                {/*        <Label htmlFor="use-workflow">Use Workflow</Label>*/}
                                {/*    </div>*/}
                                {/*    <div className="flex items-center gap-2">*/}
                                {/*        <Input*/}
                                {/*            id="use-dms"*/}
                                {/*            type="checkbox"*/}
                                {/*            checked={useDMS}*/}
                                {/*            autoFocus={false}*/}
                                {/*            placeholder="email"*/}
                                {/*            className="h-4 w-4"*/}
                                {/*            onChange={event => setUseDMS(event.target.checked)}*/}
                                {/*        />*/}
                                {/*        <Label htmlFor="use-dms">Use DMS</Label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2">
                                <Label>Emails</Label>
                                <CreatableSelect
                                    className="w-full"
                                    isMulti
                                    isClearable
                                    components={{ DropdownIndicator: null }}
                                    value={emailOptions}
                                    onChange={newValue =>
                                        handleChangeEmailOptions(newValue as unknown as Option[])
                                    }
                                />
                            </div>
                        )}
                    </div>

                    <SelectUserModal
                        isOpen={showSelectUserModal}
                        onClose={setShowSelectUserModal.off}
                        onSelectUser={handleSelectUser}
                    />
                </ModalBody>
                <ModalFooter className="gap-4">
                    <Button
                        outline
                        size="sm"
                        className="text-gray-400"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    {isSCG ? (
                        <Button
                            size="sm"
                            onClick={handleClickAdd}
                            className="bg-primary-900 text-white"
                        >
                            Add
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            onClick={handleClickSendInvite}
                            className="bg-primary-900 text-white"
                        >
                            Send Invite
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default UserVerificationInviteModal;
